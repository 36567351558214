/** @jsx jsx */

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { jsx, Flex, Box, Text } from 'theme-ui';
import Img from 'gatsby-image';
import shortid from 'shortid';

const Content = styled(Box)`
  background: #fff;
  border-radius: ${themeGet('radii.1')}px;
  border: 1px solid ${themeGet('colors.grey')};
  padding: 16px;
`;

const Title = styled('div')`
  position: absolute;
  font-size: 1.25rem;
  font-weight: bold;
  top: -15px;
  left: 15px;
  padding: 0 10px;
  background-color: #fff;
  & span {
    background: -webkit-linear-gradient(45deg, #45166a, #83157b 75%, #bc158a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Product = ({
  title,
  description,
  price,
  oldPrice,
  condition,
  image,
  ...props
}) => (
  <Flex {...props}>
    <Img fixed={image.localFile.childImageSharp.fixed} />
    <Box sx={{ flex: 1 }} ml={3}>
      <Text sx={{ fontSize: '0.8rem', color: 'brand' }}>{condition}</Text>
      <Text sx={{ color: 'brand', fontWeight: 'bold' }}>{title}</Text>
      <Text>
        <span
          style={{
            color: 'grey',
            textDecoration: 'line-through',
          }}
        >
          {oldPrice}
        </span>
        <span
          sx={{
            color: 'brand',
            fontSize: '1rem',
            fontWeight: 'bold',
            marginLeft: '4px',
          }}
        >
          {price}
        </span>
      </Text>
      <Text fontSize="0.8rem">{description}</Text>
    </Box>
  </Flex>
);
Product.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  oldPrice: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

const Gifts = ({ title, items, ...props }) =>
  items.length > 0 && (
    <Box
      sx={{
        position: 'relative',
        padding: '3px',
        background: 'linear-gradient(45deg, #45166a, #83157b 75%, #bc158a)',
        borderRadius: 'default',
      }}
      {...props}
    >
      <Box
        sx={{
          background: '#FFF',
          borderRadius: '2px',
          padding: 3,
        }}
      >
        <Title>
          <span>{title}</span>
        </Title>
        <Content>
          {items.map((product, i) => (
            <Product
              key={shortid.generate()}
              mt={i === 0 ? 0 : 4}
              title={product.product_title}
              description={product.product_description}
              price={product.price}
              oldPrice={product.old_price}
              condition={product.condition}
              image={product.product_image}
            />
          ))}
          {/* <Product
            title="Original Chalk"
            description="2oz Bar Magnesium Carbonate Kettlebell Chalk"
            price="FREE"
            oldPrice="$9.99"
            condition="Buy 1 Kettlebell Get"
          />
          <Product
            mt={4}
            title="Kettlebell Wrist Guard"
            description="Original Kettlebell Wrist Guard "
            price="FREE"
            oldPrice="$19.99"
            condition="Buy 2 Kettlebells Get"
          /> */}
        </Content>
      </Box>
    </Box>
  );

Gifts.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default Gifts;
