import { useStaticQuery, graphql } from 'gatsby';

const useSitePluginOptions = () => {
  const { sitePlugin } = useStaticQuery(
    graphql`
      query StampedPluginQuery {
        sitePlugin(name: { eq: "gatsby-source-stamped" }) {
          pluginOptions {
            apiKey
            storeUrl
          }
        }
      }
    `
  );

  return { ...sitePlugin.pluginOptions };
};
export default useSitePluginOptions;
