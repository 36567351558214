/** @jsx jsx */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import Slider from 'react-slick';
import StarRatings from 'react-star-ratings';

import { jsx, Text, Flex as Flex2, Box as Box2, useThemeUI } from 'theme-ui';

import ProductForm from './ProductForm';
// import { ProductSpecs } from './ProductDetails';
import SlicesRenderer from './SlicesRenderer';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useSitePluginOptions from '../hooks/useSitePluginOptions';

const ProductPage = ({ data }) => {
  const {
    shopifyProduct: {
      id,
      title,
      description,
      descriptionHtml,
      images,
      thumbs,
      variants,
    },
    prismicProduct,
    stampedRatingsSummary,
  } = data;


  const {
    theme: { colors },
  } = useThemeUI();

  const { apiKey, storeUrl } = useSitePluginOptions();

  useEffect(() => {
    if (window) {
      window.StampedFn.init({
        apiKey,
        storeUrl,
      });
    }
  }, []);
  const stamped = prismicProduct.data.body1.find(
    (op) => op.slice_type === 'stamped'
  );

  const settings = {
    customPaging: (i) => {
      const image = thumbs[i];
      return (
        <a>
          <Image
            key={image.id}
            fixed={image.localFile.childImageSharp.fixed}
            alt={image.altText}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Box2
      sx={{
        maxWidth: [null, null, 'contentWidth'],
        my: 0,
        mx: [0, null, 'auto'],
      }}
    >
      <Flex2
        py={[0, null, 50]}
        sx={{
          position: 'relative',
          flexDirection: ['column', null, 'row'],
        }}
      >
        <Flex2
          sx={{
            width: ['100%', null, '66.66%'],
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          {/* <Carousel width="400px">
            {images.map((image) => (
              <Image
                key={image.id}
                fluid={image.localFile.childImageSharp.fluid}
                alt={image.altText}
              />
            ))}
          </Carousel> */}
          <Slider
            {...settings}
            style={{ width: '100%', position: 'sticky', top: '80px' }}
            sx={{
              '.slick-thumb': {
                bottom: '-60px',
              },
              '.slick-thumb li': {
                width: ['50px', null, '70px'],
                height: ['30px', null, '50px'],
              },
              '.slick-thumb li .gatsby-image-wrapper': {
                width: ['50px !important', null, '70px'],
                height: ['36px !important', null, '50px'],
              },
            }}
          >
            {images.map((image) => (
              <Image
                key={image.id}
                fluid={image.localFile.childImageSharp.fluid}
                alt={image.altText}
              />
            ))}
          </Slider>
        </Flex2>
        <Box2
          sx={{
            width: ['100%', null, '33.33%'],
            px: 4,
            mt: [6, null, 0],
          }}
        >
          <Text
            as="h1"
            sx={{
              color: 'brand',
              fontSize: 4,
              textTransform: 'uppercase',
              mb: 2,
            }}
          >
            {title}
          </Text>
          <Flex2
            sx={{
              mb: 3,
              alignItems: 'center',
              '.star-ratings': {
                flexShrink: 0,
                mr: 3,
              },
            }}
          >
            {stamped && stamped.primary.show_reviews && (
              <>
                <StarRatings
                  rating={stampedRatingsSummary ? stampedRatingsSummary.rating : 5}
                  starRatedColor={colors.accent}
                  // changeRating={this.changeRating}
                  starDimension="18px"
                  starSpacing="2px"
                  numberOfStars={5}
                  name="ratingSummary"
                />
                <Text
                  sx={{
                    color: 'grey',
                    // fontWeight: 'bold',
                    fontSize: 1,
                    pt: '6px',
                  }}
                >{`Based on ${stampedRatingsSummary ? stampedRatingsSummary.count : 51} reviews.`}</Text>
              </>
            )}
          </Flex2>
          {/* <ProductSpecs title={title} description={descriptionHtml} isHtml /> */}
          <ProductForm
            id={id}
            variants={variants}
            options={prismicProduct.data.body1}
          />
        </Box2>
      </Flex2>
      <SlicesRenderer slices={prismicProduct.data.body} />
      {/* <span class="stamped-product-reviews-badge stamped-main-badge" data-id="7776714874192"></span> */}
      <Box2
        as="section"
        mt={6}
        mb={5}
        sx={{
          '.stamped-pagination': {
            a: {
              color: 'brand',
              fontWeight: 'bold',
            },
          },
        }}
      >
        {stamped && stamped.primary.show_reviews && (
          <>
            <Text as="h2" sx={{ textAlign: 'center', color: 'brand' }}>
              Reviews
            </Text>
            <div
              id="stamped-main-widget"
              data-product-id={stamped.primary.product_id}
            />
          </>
        )}
      </Box2>
    </Box2>
  );
};

ProductPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProductPage;
