import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import ProductPage from '../components/ProductPage';

const ProductPageTemplate = ({ data, location }) => {
  const {
    prismicProduct: { data: product },
  } = data;
  return (
    <>
      <SEO pathname={location.pathname} title={product.title.text} />
      <ProductPage data={data} />
    </>
  );
};

ProductPageTemplate.propTypes = {
  data: PropTypes.shape({
    shopifyProduct: PropTypes.object.isRequired,
    prismicProduct: PropTypes.object.isRequired,
    stampedRatingsSummary: PropTypes.object.isRequired,
  }).isRequired,
};

export default ProductPageTemplate;

export const query = graphql`
  query($handle: String!, $lang: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      description
      descriptionHtml
      productType
      variants {
        shopifyId
        title
        price
        availableForSale
      }
      images {
        id
        # altText
        localFile {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      thumbs: images {
        localFile {
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    prismicProduct(uid: { eq: $handle }, lang: { eq: $lang }) {
      uid
      data {
        title {
          text
        }
        body {
          ... on PrismicProductBodyHeading {
            id
            slice_type
            primary {
              heading {
                html
              }
            }
          }
          ... on PrismicProductBodyParagraph {
            id
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicProductBodyTextBlock {
            id
            slice_type
            primary {
              content {
                html
              }
              heading {
                html
              }
            }
          }
          ... on PrismicProductBodyImage {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 550) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              description
            }
          }
          ... on PrismicProductBodyImageStrip {
            id
            slice_type
            items {
              text
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 80, height: 80) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProductBodyColumns {
            id
            slice_type
            items {
              heading {
                html
              }
              content {
                html
              }
            }
          }
          ... on PrismicProductBodyCompare {
            id
            slice_type
            primary {
              title_left {
                html
              }
              title_right {
                html
              }
            }
            items {
              feature_left {
                html
              }
              feature_right {
                html
              }
              image_left {
                localFile {
                  childImageSharp {
                    fixed(width: 180, height: 180) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              image_right {
                localFile {
                  childImageSharp {
                    fixed(width: 180, height: 180) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              description_left
              description_right
            }
          }
          ... on PrismicProductBodySpecifications {
            id
            slice_type
            primary {
              heading {
                html
              }
              specification {
                document {
                  ... on PrismicSpecification {
                    id
                    data {
                      body {
                        ... on PrismicSpecificationBodySpec {
                          id
                          slice_type
                          primary {
                            title
                          }
                          items {
                            label
                            description {
                              html
                            }
                            image {
                              localFile {
                                childImageSharp {
                                  fixed(width: 200, height: 200) {
                                    ...GatsbyImageSharpFixed
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body1 {
          ... on PrismicProductBody1Gift {
            id
            slice_type
            items {
              condition
              product_title
              product_description
              product_image {
                localFile {
                  childImageSharp {
                    fixed(width: 60, height: 60) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              old_price
              price
            }
          }
          ... on PrismicProductBody1ProductVariantsForm {
            id
            slice_type
            primary {
              heading {
                text
              }
              subheading
            }
          }
          ... on PrismicProductBody1Stamped {
            id
            slice_type
            primary {
              product_id
              show_reviews
              show_stars
            }
          }
        }
      }
    }
    stampedRatingsSummary(productId: { eq: "7776714874192" }) {
      rating
      count
      breakdown {
        rating1
        rating2
        rating3
        rating4
        rating5
      }
      badge
    }
  }
`;
