/** @jsx jsx */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import {
  MdErrorOutline,
  MdShoppingCart,
  MdSentimentDissatisfied,
} from 'react-icons/md';

import { jsx, Input, Select, Flex, Button } from 'theme-ui';

import StoreContext from '../context/StoreContext';
import Gifts from './Gifts';
import ProductVariantsForm from './ProductVariantsForm';

const Errors = styled(`div`)`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: row;
  margin-bottom: ${(props) => themeGet('space.4')}px;
  width: 100%;
`;

const ErrorSign = styled(`div`)`
  align-items: center;
  background: ${(props) => themeGet('colors.error')};
  border-radius: 2px 0 0 2px;
  color: ${(props) => themeGet('colors.lightest')};
  display: flex;
  flex-basis: 40px;
  justify-content: center;

  svg {
    height: 20px;
    width: 20px;
  }
`;

const ErrorMsgs = styled(`ul`)`
  border: 1px dashed ${(props) => themeGet('colors.error')};
  border-left: none;
  border-radius: 0 2px 2px 0;
  color: ${(props) => themeGet('colors.error')};
  flex-grow: 1;
  margin: 0;
  padding: ${(props) => themeGet('space.4')}px;
  padding-left: ${(props) => themeGet('space.8')}px;
`;

const Price = styled(`div`)`
  color: ${(props) => themeGet('colors.brand')};
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: -0.02em;

  span {
    color: ${(props) => themeGet('colors.textLight')};
  }
`;

const UpsellMessage = styled('div')`
  color: #bc158a;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0 0 16px 0;
`;

const Label = ({ children, ...props }) => (
  <label
    sx={{
      color: 'brown',
      textTransform: 'uppercase',
      display: 'flex',
      fontSize: 1,
      fontWeight: 'bold',
      py: 3,
    }}
    {...props}
  >
    {children}
  </label>
);
const Fieldset = ({ children, ...props }) => (
  <fieldset
    sx={{
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      margin: 0,
      padding: 0,
    }}
    {...props}
  >
    {children}
  </fieldset>
);

class ProductForm extends Component {
  constructor(props) {
    super(props);

    const { variants } = this.props;
    this.state = {
      // variant: variants.length === 1 ? variants[0].shopifyId : '',
      variant: variants.length >= 1 ? variants[0].shopifyId : '',
      quantity: 1,
      errors: [],
    };
  }

  incQuantity = (event) => {
    event.preventDefault();
    const { quantity } = this.state;
    this.setState({ quantity: quantity + 1 });
  };

  decQuantity = (event) => {
    event.preventDefault();
    const { quantity } = this.state;
    if (quantity <= 1) return;
    this.setState({ quantity: quantity - 1 });
  };

  handleChange = (event) => {
    event.preventDefault();

    if (event.target.value) {
      const { errors } = this.state;

      const errorIdx = errors.findIndex(
        (error) => error.field === event.target.name
      );

      errors.splice(errorIdx, 1);

      if (~errorIdx) {
        this.setState({ errors });
      }
    }

    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (callback, currency) => (event) => {
    event.preventDefault();

    const errors = [];
    const { quantity, variant } = this.state;

    // For FB Pixel tracking
    const { variants } = this.props;
    const productVariant = variants.find((pv) => pv.shopifyId === variant);

    if (quantity < 1) {
      errors.push({
        field: 'quantity',
        msg: 'Choose a <b>quantity</b> of 1 or more.',
      });
    }

    if (variant === '' || variant === '.') {
      errors.push({
        field: 'variant',
        msg: 'Please select a <b>size</b>.',
      });
    }

    if (errors.length) {
      this.setState({ errors });
      return;
    }

    // For FB Pixel tracking
    if (typeof window !== 'undefined' && typeof fbq === `function`) {
      const data = {
        content_name: productVariant.title,
        // content_category: 'Apparel & Accessories > Shoes',
        content_ids: [productVariant.shopifyId],
        content_type: 'product',
        value: productVariant.price,
        currency,
      };
      window.fbq('track', 'AddToCart', data);
    }

    callback(variant, quantity);
  };

  priceForVariantId = (variantId) => {
    const unknownPrice = '0.00';

    if (variantId === '') return unknownPrice;

    const { variants } = this.props;
    const result = variants.find((variant) => variant.shopifyId === variantId);

    return result ? result.price : unknownPrice;
  };

  // checkAvailability = ({ productId }) => {
  //   this.context.client.product.fetch(productId).then(product => {
  //     // this checks the currently selected variant for availability
  //     const result = product.variants.filter(
  //       variant => variant.id === productVariant.shopifyId
  //     );
  //     setAvailable(result[0].available);
  //   });
  // };

  render() {
    const { variants, options } = this.props;
    const { errors, variant, quantity } = this.state;

    let giftsItems = [];
    const variantsForm = options.find(
      (op) => op.slice_type === 'product_variants_form'
    );
    const gifts = options.find((op) => op.slice_type === 'gifts');
    if (gifts) {
      giftsItems = gifts.items;
    }

    const hasVariants = variants.length > 1;

    const isOutOfStock = !hasVariants && !variants[0].availableForSale;

    const upsellNotification = (lineItems) => {
      const itemsInCart = lineItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      switch (itemsInCart) {
        case 1: {
          return (
            <UpsellMessage>
              Add 2 more items and get FREE shipping.
            </UpsellMessage>
          );
        }
        case 2: {
          return (
            <UpsellMessage>
              Add 1 more item and get FREE shipping.
            </UpsellMessage>
          );
        }
        case 3: {
          return (
            <UpsellMessage>Add 1 more item and get 10% discount.</UpsellMessage>
          );
        }
        default:
          return null;
      }
    };

    return (
      <StoreContext.Consumer>
        {({ addVariantToCart, checkout, addVariantsToCart }) => (
          <form
            onSubmit={this.handleSubmit(
              addVariantToCart,
              checkout.currencyCode
            )}
            noValidate
          >
            {/* <Price>
              {checkout.currencyCode} {this.priceForVariantId(variant)}
            </Price> */}
            <Errors show={errors.length}>
              <ErrorSign>
                <MdErrorOutline />
              </ErrorSign>
              <ErrorMsgs>
                {errors.map((error) => (
                  <li
                    key={error.field}
                    dangerouslySetInnerHTML={{ __html: error.msg }}
                  />
                ))}
              </ErrorMsgs>
            </Errors>
            <Flex mb={4} sx={{ flexDirection: 'column-reverse' }}>
              <Fieldset>
                <Label htmlFor="quantity">Quantity</Label>
                {/* {upsellNotification(checkout.lineItems)} */}
                <Flex sx={{ alignItems: 'center' }}>
                  <Button variant="empty" onClick={(e) => this.decQuantity(e)}>
                    -
                  </Button>
                  <Input
                    type="number"
                    id="quantity"
                    name="quantity"
                    min="1"
                    step="1"
                    onChange={this.handleChange}
                    value={quantity}
                    sx={{
                      flexGrow: 0,
                      textAlign: 'center',
                      width: [50, null, 30],
                      fontSize: 2,
                      color: 'brand',
                      border: ({ colors }) => `1px solid ${colors.lightGrey}`,
                      borderRadius: 'default',

                      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '&[type="number"]': {
                        '-moz-appearance': 'textfield',
                      },
                    }}
                  />
                  <Button variant="empty" onClick={(e) => this.incQuantity(e)}>
                    +
                  </Button>
                </Flex>
              </Fieldset>
              {hasVariants && (
                <Fieldset>
                  <Label htmlFor="variant">Select Size</Label>
                  {/* <UpsellMessage>Only a few left</UpsellMessage> */}
                  <Select
                    id="variant"
                    value={variant}
                    name="variant"
                    onChange={this.handleChange}
                    sx={{
                      fontSize: 2,
                      border: ({ colors }) => `1px solid ${colors.lightGrey}`,
                      borderRadius: 'default',
                    }}
                  >
                    {/* <option disabled value="">
                    Choose Size
                  </option> */}
                    {variants.map((productVariant) => (
                      <option
                        disabled={!productVariant.availableForSale}
                        value={productVariant.shopifyId}
                        key={productVariant.shopifyId}
                      >
                        {productVariant.title} ({checkout.currencyCode}{' '}
                        {productVariant.price})
                      </option>
                    ))}
                  </Select>
                </Fieldset>
              )}
            </Flex>
            <Button
              id="add-to-cart"
              variant="cart.checkout"
              disabled={isOutOfStock}
            >
              {isOutOfStock ? 'Out of Stock' : 'Add to Cart'}
              {isOutOfStock ? <MdSentimentDissatisfied /> : <MdShoppingCart />}
            </Button>
            {giftsItems.length > 0 && (
              <Gifts my={5} title="Free Premium Gift" items={giftsItems} />
            )}
            <Label>Complete the set</Label>
            <ProductVariantsForm
              title={
                variantsForm
                  ? variantsForm.primary.heading.text
                  : 'Buy 3 save 15%'
              }
              subtitle={
                variantsForm
                  ? variantsForm.primary.subheading
                  : 'Buy 3 or more items and save 15%!'
              }
              variants={variants}
              currency={checkout.currencyCode}
              mb={3}
              addVariantsToCart={addVariantsToCart}
              upsellNotification={() => upsellNotification(checkout.lineItems)}
            />
          </form>
        )}
      </StoreContext.Consumer>
    );
  }
}

ProductForm.propTypes = {
  id: PropTypes.string.isRequired,
  variants: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
};

export default ProductForm;
