/** @jsx jsx */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import shortid from 'shortid';
import { jsx, Input, Button, Text, Box, Flex } from 'theme-ui';

import { MdShoppingCart } from 'react-icons/md';
import InterfaceContext from '../context/InterfaceContext';

const Title = styled('div')`
  color: ${themeGet('colors.brand')};
  font-size: 1.25rem;
  font-weight: bold;
  padding: 16px 24px 4px 24px;
`;
const Subtitle = styled('div')`
  color: ${themeGet('colors.brand')};
  font-size: 0.75rem;
  font-weight: bold;
  padding: 4px 24px 16px 24px;
`;

const ProductVariantsForm = ({
  title,
  subtitle,
  variants,
  currency,
  addVariantsToCart,
  upsellNotification,
  ...props
}) => {
  const initQuantities = variants.map((v) => ({ ...v, quantity: 0 }));
  const [quantities, setQuantities] = useState(initQuantities);
  const { toggleCart } = useContext(InterfaceContext);

  const updateQuantities = (e, shopifyId, value) => {
    e.preventDefault();
    const variantIndex = quantities.findIndex((v) => v.shopifyId === shopifyId);

    if (quantities[variantIndex].quantity + value < 0) {
      return;
    }

    if (variantIndex !== -1) {
      const newQuantities = [...quantities];
      newQuantities[variantIndex].quantity += value;
      setQuantities(newQuantities);
    }
  };

  const bulkAddToCart = async (e) => {
    e.preventDefault();
    const variantsToAdd = [];
    quantities.forEach((variant) => {
      if (variant.quantity > 0) {
        // addVariantToCart(variant.shopifyId, variant.quantity);
        variantsToAdd.push({
          variantId: variant.shopifyId,
          quantity: parseInt(variant.quantity, 10),
        });

        // For FB Pixel tracking
        if (typeof window !== 'undefined' && typeof fbq === `function`) {
          const data = {
            content_name: variant.title,
            // content_category: 'Apparel & Accessories > Shoes',
            content_ids: [variant.shopifyId],
            content_type: 'product',
            value: variant.price,
            currency,
          };
          window.fbq('track', 'AddToCart', data);
        }
      }
    });
    if (variantsToAdd.length > 0) {
      await addVariantsToCart(variantsToAdd);
      toggleCart();

      // Reset the form
      const newQuantities = [...quantities];
      newQuantities.forEach((variant) => {
        variant.quantity = 0;
      });

      setQuantities(newQuantities);
    }
  };

  const symbolForCurrency = (currencyString) => {
    switch (currencyString) {
      case 'USD':
        return '$';
      case 'GBP':
        return '£';

      default:
        return currencyString;
    }
  };

  return (
    <Box
      sx={{
        bg: 'brandBright',
        borderRadius: 'default',
      }}
      {...props}
    >
      <Box sx={{ p: '1px' }}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Box
          sx={{
            bg: 'white',
            px: 3,
            py: 1,
            borderBottomLeftRadius: 'default',
            borderBottomRightRadius: 'default',
          }}
        >
          {/* {upsellNotification()} */}
          {quantities.map((v) => (
            <Flex
              key={shortid.generate()}
              sx={{
                mb: 4,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Flex alignItems="center">
                <Button
                  variant="empty"
                  onClick={(e) => updateQuantities(e, v.shopifyId, -1)}
                >
                  -
                </Button>
                <Input
                  type="number"
                  id="quantity"
                  name="quantity"
                  min="0"
                  step="1"
                  // onChange={this.handleChange}
                  value={v.quantity}
                  sx={{
                    flexGrow: 0,
                    textAlign: 'center',
                    width: [50, null, 30],
                    fontSize: 2,
                    color: 'brand',

                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                    '&[type="number"]': {
                      '-moz-appearance': 'textfield',
                    },
                  }}
                />
                <Button
                  variant="empty"
                  onClick={(e) => updateQuantities(e, v.shopifyId, 1)}
                >
                  +
                </Button>
              </Flex>
              <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'brand' }}>
                {v.title}
              </Text>
              <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'brand' }}>
                {`${symbolForCurrency(currency)} `}
                {v.price}
              </Text>
            </Flex>
          ))}
          <Button
            id="add-to-cart-bulk"
            variant="cart.checkout"
            onClick={(e) => bulkAddToCart(e)}
          >
            Add to Cart
            <MdShoppingCart />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ProductVariantsForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  variants: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  addVariantsToCart: PropTypes.func.isRequired,
  upsellNotification: PropTypes.func.isRequired,
};

export default ProductVariantsForm;
